import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IRetailerMaster, IRetailerMasterProps } from "./IRetailerMaster";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import {
  clearCreateRetailerMaster,
  createRetailerMasterStart,
} from "../../redux/retail-master/createRetailerMaster";
import {
  clearEditRetailerMaster,
  editRetailerMasterStart,
} from "../../redux/retail-master/editRetailerMaster";
import CompletePopup from "../components/response-pop-up";
import AutoComplete from "../components/autocomplete-input";
import { Button } from "react-bootstrap";

const RetailerMasterForm: FunctionComponent<IRetailerMasterProps> = (props) => {
  const { handleClose, setInitialValues, initialValues } = props;
  const [isEdit, setEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // const { data: listRetailer, isLoading: listRetailerLoading } = useAppSelector(
  //   (state: any) => state.ListRetailerMaster
  // );

  const stateOpetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createReatilerLoading, data: createRetailer } =
    useAppSelector((state) => state.CreateRetailerMaster);

  const { isLoading: editRetailerLoading, data: editRetailer } = useAppSelector(
    (state) => state.EditRetailerMaster
  );

  const state_option = stateOpetionList?.data?.result
    .filter((e: any) => {
      if (e.page === "state") {
        return e;
      }
    })
    .map((e: any) => {
      const data = { label: e.description, value: e.name };
      return data;
    });

  const retailer_option = stateOpetionList?.data?.result
    .filter((e: any) => {
      if (e.page === "Retail Master Type") {
        return e;
      }
    })
    .map((e: any) => {
      const data = { label: e.description, value: e.name };
      return data;
    });

  console.log("retailer option", retailer_option);

  var gstinformat =
    /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;

  //   validation
  const validationSchema = Yup.object().shape({
    id: Yup.number().optional(),
    name: Yup.string()
      .max(50, "maximum 50 character")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Retailer Name Required"),
    address1: Yup.string()
      .max(100, "maximum 100 character")
      .required("Address Required"),
    address2: Yup.string().max(100, "maximum 100 character").optional(),
    gst: Yup.mixed()
      .required("Gst Number Required")
      .test("gstvalidate", "invalid gst number", (value: any) => {
        if (gstinformat.test(value)) {
          return true;
        } else {
          return false;
        }
      }),
    city: Yup.string()
      .max(100, "maximum 100 character")
      .required("City Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    state: Yup.string().required("State Required"),
    contact_name: Yup.string()
      .max(50, "maximum 50 character")
      .required("Contact Name Required"),
    contact_number: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(15, "maximum 15 character")
      .required("Contact Number Required"),
    email: Yup.string()
      .email("email is invalid")
      .max(100, "maximum 100 character")
      .required("Email Required"),
    comments: Yup.string().max(50, "maximum 50 character").optional(),
    active: Yup.boolean().optional(),
  });

  const formik = useFormik<IRetailerMaster>({
    enableReinitialize: true,
    initialValues: {
      address1: "",
      name: "",
      address2: "",
      city: "",
      gst: "",
      state: "",
      email: "",
      contact_name: "",
      contact_number: "",
      comments: "",
      active: initialValues.active ? initialValues.active : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    setValues,
    validateForm,
  } = formik;

  console.log(touched);

  const submit = (a: IRetailerMaster) => {
    if (a.id) {
      dispatch(editRetailerMasterStart(a));
    } else {
      dispatch(createRetailerMasterStart(a));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      setValues(initialValues);
    }
    return () => {
      setInitialValues({});
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column">
          <div className="row">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="name"
                label="Retailer Name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Retailer Type <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  retailer_option.find((e: any) => {
                    return e.value === values.type;
                  }) ?? null
                }
                options={retailer_option}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("type", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.type && errors.type ? true : false}
                    helperText={touched.type && errors.type ? errors.type : ""}
                    name="type"
                    placeholder="Select Retailer Type"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="email"
                label="Email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="address1"
                label="Address Line1"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="address2"
                label="Address Line2"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                notRequire
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="city"
                label="City"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                State <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  state_option.find((e: any) => {
                    return e.value === values.state;
                  }) ?? null
                }
                options={state_option}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("state", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.state && errors.state ? true : false}
                    helperText={
                      touched.state && errors.state ? errors.state : ""
                    }
                    name="state"
                    placeholder="Select State"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="gst"
                label="Gst Number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="contact_name"
                label="Contact Name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="contact_number"
                label="Contact Number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="comments"
                label="Description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                notRequire
              />
            </div>
            <div className="col-12 mt-4">
              <div className="col-6 d-flex ms-3 align-items-center">
                <FormControlLabel
                  value={values.active}
                  control={<Checkbox checked={values.active} />}
                  label="Is Active"
                  onChange={(_, val) => {
                    setFieldValue("active", val);
                  }}
                  name="active"
                  labelPlacement="end"
                  sx={{
                    fontWeight: "700",
                  }}
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  onClick={() => {
                    handleClose();
                    setEdit(false);
                  }}
                  className="cancel"
                >
                  Cancel
                </div>
              </div>
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  className="add"
                  onClick={() => {
                    handleSubmit();
                    validateForm();
                  }}
                >
                  {createReatilerLoading || editRetailerLoading
                    ? "Please Wait..."
                    : "Save"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default RetailerMasterForm;
