import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IDistributorMaster } from "../../pages/distributor/IDistributorMaster";

const EditDistributorMaster = createSlice({
  name: "EditDistributorMaster",
  initialState: {} as IReduxState<IDistributorMaster>,
  reducers: {
    editDistributorMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successEditDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureEditDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearEditDistributorMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  editDistributorMasterStart,
  successEditDistributorMaster,
  failureEditDistributorMaster,
  clearEditDistributorMaster,
} = EditDistributorMaster.actions;

export default EditDistributorMaster.reducer;
