import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IDistributorMaster } from "../../pages/distributor/IDistributorMaster";

const ListDistributorMaster = createSlice({
  name: "ListDistributorMaster",
  initialState: {} as IReduxState<IDistributorMaster[]>,
  reducers: {
    listDistributorMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successListDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureListDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearListDistributorMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  listDistributorMasterStart,
  successListDistributorMaster,
  failureListDistributorMaster,
  clearListDistributorMaster,
} = ListDistributorMaster.actions;

export default ListDistributorMaster.reducer;
