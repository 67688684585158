import { Backdrop, InputAdornment, TextField } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { listRetailerMasterStart } from "../../redux/retail-master/listRetailerMaster";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { useSearchParams } from "react-router-dom";
import { IRetailerMaster } from "./IRetailerMaster";
import RetailerMasterList from "./RetailerMasterList";
import { clearCreateRetailerMaster } from "../../redux/retail-master/createRetailerMaster";
import { clearEditRetailerMaster } from "../../redux/retail-master/editRetailerMaster";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";

const RetailerMaster: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [retailer, setRetailer] = useState<IRetailerMaster>({});
  const [search, setSearch] = useState<string>("");

  const dispatch = useAppDispatch();
  const { data: retailerList, isLoading: listRetailerLoading } = useAppSelector(
    (state) => state.ListRetailerMaster
  );
  const { isLoading: createReatilerLoading, data: createRetailer } =
    useAppSelector((state) => state.CreateRetailerMaster);

  const { isLoading: editRetailerLoading, data: editRetailer } = useAppSelector(
    (state) => state.EditRetailerMaster
  );
  const ListRetailer =
    retailerList?.result?.map((retailer, index) => ({
      ...retailer,
      index: index + 1,
    })) ?? [];

  // list render
  useEffect(() => {
    dispatch(listRetailerMasterStart(null));
  }, [createRetailer, editRetailer]);

  //clear create edit data and get the state list
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    return () => {
      dispatch(clearCreateRetailerMaster());
      dispatch(clearEditRetailerMaster());
    };
  }, []);

  useEffect(() => {
    if (ListRetailer?.length !== 0) {
      const retailerCheckById =
        searchParams.get("id") &&
        ListRetailer?.find(
          (retail) => retail.id === Number(searchParams.get("id"))
        );

      if (retailerCheckById) {
        setRetailer(retailerCheckById);
      } else {
        // setRetailer(ListRetailer[0]!);
        setSearchParams({ id: ListRetailer[0]?.id?.toString() ?? "" });
      }
    }
  }, [
    searchParams.get("id"),
    ListRetailer?.length,
    createRetailer,
    editRetailer,
  ]);

  return (
    <div className="d-flex">
      <>
        <div className="list col-5 ">
          <div className=" px-3 my-4  d-flex align-items-center">
            <div className="title d-flex ">
              <h5>Retailer Master</h5>
            </div>

            <div className="filter ms-auto me-3 d-flex align-items-center">
              <TextField
                placeholder="Search"
                variant="outlined"
                className="w-100 me-2"
                name="search"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "0px",
                    height: "45px",
                  },
                }}
              />
              <FontAwesomeIcon
                onClick={() => {
                  dispatch(listRetailerMasterStart(null));
                }}
                className="mx-2"
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                icon={["fas", "arrows-rotate"]}
              />
            </div>
          </div>
          <div
            className="list"
            style={{
              overflow: "scroll",
              height: "80vh",
              background: "#fafafa",
            }}
          >
            <>
              {ListRetailer &&
                ListRetailer.filter((item) => {
                  return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }).map((val, key) => (
                  <>
                    <div
                      key={key + 1}
                      id={retailer.id === val.id ? "active-lists" : ""}
                      className={"d-flex lists flex-column mt-2  p-3"}
                      style={{
                        height: "100px",
                        background: "#FFFFFF",
                      }}
                      onClick={() => {
                        val.id && setSearchParams({ id: val.id?.toString() });
                      }}
                    >
                      <div className="mx-2 partner-name">{val.name}</div>
                      <div className="d-flex mt-auto partner-locations align-items-center">
                        <p className="ms-2 me-auto mb-0">{val.address1}</p>
                        <p className={"ms-auto me-2 mb-0 text-yellow"}>
                          {val.active ? "Active" : "InActive"}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </>
          </div>
        </div>
      </>
      <>
        <RetailerMasterList retailer={retailer} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!listRetailerLoading}
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
      </>
    </div>
  );
};

export default RetailerMaster;
