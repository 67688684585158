import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderItemDetail } from "../../pages/orderDetailMaster/IOrderDetailMaster";
import { FAILED } from "../login-and-create-password/login-reducer";

//item master list filter
const ListOrderDetailItemMaster = createSlice({
  name: "ListOrderDetailItemMaster",
  initialState: {} as IReduxState<IOrderItemDetail[]>,
  reducers: {
    listOrderDetailItemMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successListOrderDetailItemMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;

      action.payload?.result?.length === 0 && FAILED(action.payload);
    },
    failureListOrderDetailItemMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      FAILED(action.payload);
    },
    clearListOrderDetailItemMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  listOrderDetailItemMasterStart,
  successListOrderDetailItemMaster,
  failureListOrderDetailItemMaster,
  clearListOrderDetailItemMaster,
} = ListOrderDetailItemMaster.actions;

export default ListOrderDetailItemMaster.reducer;
