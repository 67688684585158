import { createRef, FunctionComponent, useEffect, useState } from "react";
import { IRetailerMaster, RetailerMasterListProps } from "./IRetailerMaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import RetailerMasterForm from "./RetailerMasterForm";
import CompletePopup from "../components/response-pop-up";
import { clearCreateRetailerMaster } from "../../redux/retail-master/createRetailerMaster";
import { clearEditRetailerMaster } from "../../redux/retail-master/editRetailerMaster";

const RetailerMasterList: FunctionComponent<RetailerMasterListProps> = (
  props
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IRetailerMaster>({
    active: true,
  });
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const dispatch = useAppDispatch();

  const stateOpetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createReatilerLoading, data: createRetailer } =
    useAppSelector((state) => state.CreateRetailerMaster);

  const { isLoading: editRetailerLoading, data: editRetailer } = useAppSelector(
    (state) => state.EditRetailerMaster
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const { retailer } = props;

  useEffect(() => {
    if (
      Object.keys(editRetailer ?? {}).length ||
      Object.keys(createRetailer ?? {}).length
    ) {
      handleClose();
    }
  }, [editRetailer, createRetailer]);
  return (
    <>
      <div
        className="col-7 tracking pt-4 px-4"
        style={{
          height: "100vh",
        }}
      >
        <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
          <div
            className="mx-4 add-branch"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Retailer
          </div>
          <div>
            <FontAwesomeIcon
              className="mx-2"
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "pen-to-square"]}
              onClick={() => {
                handleClickOpen();
                setInitialValues(retailer ?? {});
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 partner-detail-data mt-4">
            <label>Retailer Name</label>
            <p>{retailer?.name}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Address Line1</label>
            <p>{retailer?.address1}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Address Line2</label>
            <p>{retailer?.address2}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Gst NO</label>
            <p>{retailer?.gst}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>City</label>
            <p>{retailer?.city}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>State</label>
            <p>
              {stateOpetionList?.data?.result?.find((e: any) => {
                return e.name === retailer?.state;
              })?.description ?? null}
            </p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Contact Name</label>
            <p>{retailer?.contact_name}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Contact Number</label>
            <p>{retailer?.contact_number}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Email</label>
            <p>{retailer?.email}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Comments</label>
            <p>{retailer?.comments}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Status</label>
            <p>{retailer?.active ? "Active" : "InActive"}</p>
          </div>
        </div>
        {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
      </div>
      <>
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Add Retailer"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <RetailerMasterForm
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </DialogContent>
        </Dialog>

        <CompletePopup
          data={{ data: createRetailer }}
          responsePopup={Object.keys(createRetailer ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateRetailerMaster());
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editRetailer }}
          responsePopup={Object.keys(editRetailer ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditRetailerMaster());
            setResponseEdit(false);
          }}
        />
      </>
    </>
  );
};

export default RetailerMasterList;
