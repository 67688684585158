import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IRetailerMaster } from "../../pages/retailer-master/IRetailerMaster";

const EditRetailerMaster = createSlice({
  name: "EditRetailerMaster",
  initialState: {} as IReduxState<IRetailerMaster>,
  reducers: {
    editRetailerMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successEditRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureEditRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearEditRetailerMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  editRetailerMasterStart,
  successEditRetailerMaster,
  failureEditRetailerMaster,
  clearEditRetailerMaster,
} = EditRetailerMaster.actions;

export default EditRetailerMaster.reducer;
