import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IItemDetailMaster } from "../../pages/itemDetailMaster/IItemDetailMaster";

const EditItemDetailMaster = createSlice({
  name: "EditItemDetailMaster",
  initialState: {} as IReduxState<IItemDetailMaster[]>,
  reducers: {
    editItemDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successEditItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureEditItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearEditItemDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  editItemDetailMasterStart,
  successEditItemDetailMaster,
  failureEditItemDetailMaster,
  clearEditItemDetailMaster,
} = EditItemDetailMaster.actions;

export default EditItemDetailMaster.reducer;
