import { PayloadAction } from "@reduxjs/toolkit";
import api from "../axios/middleWare";
import { IRetailerMaster } from "../../pages/retailer-master/IRetailerMaster";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failureCreateRetailerMaster,
  successCreateRetailerMaster,
} from "./createRetailerMaster";
import {
  failureListRetailerMaster,
  successListRetailerMaster,
} from "./listRetailerMaster";
import {
  failureEditRetailerMaster,
  successEditRetailerMaster,
} from "./editRetailerMaster";
import {
  failureDeleteRetailerMaster,
  successDeleteRetailerMaster,
} from "./deleteRetailerMaster";

// List Retail master
const ListRetailerMasterApi = (action: PayloadAction<IRetailerMaster>) => {
  return api.get("/api/retailmaster/list", { params: action.payload });
};

export function* ListRetailerMasterCall(
  action: PayloadAction<IRetailerMaster>
) {
  try {
    const response: AxiosResponse = yield call(ListRetailerMasterApi, action);
    yield put(successListRetailerMaster(response.data));
  } catch (error) {
    yield put(failureListRetailerMaster(error));
  }
}

// Create Retail master
const CreateRetailerMasterApi = (action: PayloadAction<IRetailerMaster>) => {
  return api.post("/api/retailmaster/create", action.payload);
};

export function* CreateRetailerMasterCall(
  action: PayloadAction<IRetailerMaster>
) {
  try {
    const response: AxiosResponse = yield call(CreateRetailerMasterApi, action);
    yield put(successCreateRetailerMaster(response.data));
  } catch (error) {
    yield put(failureCreateRetailerMaster(error));
  }
}

// update Retail master
const UpdateRetailerMasterApi = (action: PayloadAction<IRetailerMaster>) => {
  return api.patch("/api/retailmaster/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* UpdateRetailerMasterCall(
  action: PayloadAction<IRetailerMaster>
) {
  try {
    const response: AxiosResponse = yield call(UpdateRetailerMasterApi, action);
    yield put(successEditRetailerMaster(response.data));
  } catch (error) {
    yield put(failureEditRetailerMaster(error));
  }
}

// Delete Retail master
const DeleteRetailerMasterApi = (action: PayloadAction<IRetailerMaster>) => {
  return api.delete("/api/retailmaster/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteRetailerMasterCall(
  action: PayloadAction<IRetailerMaster>
) {
  try {
    const response: AxiosResponse = yield call(DeleteRetailerMasterApi, action);
    yield put(successDeleteRetailerMaster(response.data));
  } catch (error) {
    yield put(failureDeleteRetailerMaster(error));
  }
}
