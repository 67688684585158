import { createRef, FunctionComponent, useEffect, useState } from "react";
import {
  IItemDetailMaster,
  ItemDetailMasterListProps,
} from "./IItemDetailMaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CompletePopup from "../components/response-pop-up";
import itemSampleFile from "../../assets/images/item-sample-export.xlsx";
import ItemDetailMasterForm from "./ItemDetailMasterForm";
import {
  clearCreateItemDetailMaster,
  createItemDetailMasterStart,
} from "../../redux/itemdetail-master/createItemDetailMaster";
import { clearEditItemDetailMaster } from "../../redux/itemdetail-master/editItemDetailMaster";

const ItemDetailMasterList: FunctionComponent<ItemDetailMasterListProps> = (
  props
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IItemDetailMaster>({
    active: true,
  });
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const dispatch = useAppDispatch();

  const stateOpetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createItemDetailLoading, data: createItemDetail } =
    useAppSelector((state) => state.CreateItemDetailMaster);

  const { isLoading: editItemDetailLoading, data: editItemDetail } =
    useAppSelector((state) => state.EditItemDetailMaster);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const { itemDetail } = props;

  useEffect(() => {
    if (
      Object.keys(editItemDetail ?? {}).length ||
      Object.keys(createItemDetail ?? {}).length
    ) {
      handleClose();
    }
  }, [editItemDetail, createItemDetail]);
  return (
    <>
      <div
        className="col-7 tracking pt-4 px-4"
        style={{
          height: "100vh",
        }}
      >
        <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
          {/* <div
            className="mx-4 add-branch"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Item
          </div> */}
          <label htmlFor="upload-file" className="mx-4">
            <FontAwesomeIcon
              title="import"
              className="mx-2"
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "file-arrow-down"]}
              onClick={() => {}}
            />
            <input
              type="file"
              style={{ display: "none" }}
              id="upload-file"
              onChange={(event) => {
                const formData = new FormData();
                if (event?.target?.files && event?.target?.files?.length > 0) {
                  formData.append("file", event.target.files[0]);
                  dispatch(createItemDetailMasterStart(formData));
                }
              }}
            />
          </label>

          <div>
            <div className="mx-4">
              <IconButton
                style={{ width: "20px", height: "20px" }}
                href={itemSampleFile}
                download="item-sample-export.xlsx"
              >
                <FontAwesomeIcon
                  title="sample-download"
                  className="mx-2"
                  style={{
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  icon={["fass", "download"]}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 partner-detail-data mt-4">
            <label>Product Name</label>
            <p>{itemDetail?.product}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Barcode</label>
            <p>{itemDetail?.barcode}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Style</label>
            <p>{itemDetail?.style}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Color</label>
            <p>{itemDetail?.color}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Size</label>
            <p>{itemDetail?.size}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Order</label>
            <p>{itemDetail?.order}</p>
            {/* <p> */}
            {/* {stateOpetionList?.data?.result?.find((e: any) => { */}
            {/* // return e.name === itemDetail?.state;
            //   })?.description ?? null} */}
            {/* </p> */}
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>MRP</label>
            <p>{itemDetail?.mrp}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Status</label>
            <p>{itemDetail?.active ? "Active" : "InActive"}</p>
          </div>
        </div>
        {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
      </div>
      <>
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Add ItemDetails"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <ItemDetailMasterForm
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </DialogContent>
        </Dialog>

        <CompletePopup
          data={{ data: createItemDetail }}
          responsePopup={Object.keys(createItemDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateItemDetailMaster());
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editItemDetail }}
          responsePopup={Object.keys(editItemDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditItemDetailMaster());
            setResponseEdit(false);
          }}
        />
      </>
    </>
  );
};

export default ItemDetailMasterList;
