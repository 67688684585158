import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IRetailerMaster } from "../../pages/retailer-master/IRetailerMaster";

const CreateRetailerMaster = createSlice({
  name: "CreateRetailerMaster",
  initialState: {} as IReduxState<IRetailerMaster[]>,
  reducers: {
    createRetailerMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateRetailerMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  createRetailerMasterStart,
  successCreateRetailerMaster,
  failureCreateRetailerMaster,
  clearCreateRetailerMaster,
} = CreateRetailerMaster.actions;

export default CreateRetailerMaster.reducer;
