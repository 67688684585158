import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  IOrderDetailMaster,
  IOrderDetailMasterProps,
} from "./IOrderDetailMaster";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import * as Yup from "yup";
import {
  useFormik,
  FieldArray,
  FormikProvider,
  FieldArrayRenderProps,
} from "formik";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Button } from "react-bootstrap";
import { createItemDetailMasterStart } from "../../redux/itemdetail-master/createItemDetailMaster";
import { editItemDetailMasterStart } from "../../redux/itemdetail-master/editItemDetailMaster";
import {
  clearEditOrderDetailMaster,
  editOrderDetailMasterStart,
} from "../../redux/orderdetail-master/editOrderDetailMaster";
import {
  clearCreateOrderDetailMaster,
  createOrderDetailMasterStart,
} from "../../redux/orderdetail-master/createOrderDetailMaster";
import {
  clearListOrderDetailItemMaster,
  listOrderDetailItemMasterStart,
} from "../../redux/orderdetail-master/searchOrderDetailItems";
import { clearListDistributorMaster } from "../../redux/distributor-master/listDistributorMaster";
import CompletePopup from "../components/response-pop-up";
import { clearListOrderDetailMaster } from "../../redux/orderdetail-master/listOrderDetailMaster";

const OrderDetailMasterForm: FunctionComponent<IOrderDetailMasterProps> = (
  props
) => {
  const { handleClose, setInitialValues, initialValues } = props;
  const [isEdit, setEdit] = useState<boolean>(false);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const [existId, setExistId] = useState<number | string>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const ArrayHelperRef = useRef<FieldArrayRenderProps>();

  const dispatch = useAppDispatch();

  // const { orderDetail } = props;

  const opetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { data: itemList } = useAppSelector(
    (state) => state.ListOrderDetailItemMaster
  );

  const { data: retailerList } = useAppSelector(
    (state) => state.ListRetailerMaster
  );

  const { data: distributorList } = useAppSelector(
    (state) => state.ListDistributorMaster
  );

  const { isLoading: createOrderDetailLoading, data: createOrderDetail } =
    useAppSelector((state) => state.CreateOrderDetailMaster);

  const { isLoading: editOrderDetailLoading, data: editOrderDetail } =
    useAppSelector((state) => state.EditOrderDetailMaster);

  const retailer_option = retailerList?.result?.map((e: any) => {
    const data = { label: e.name, value: e.id };
    return data;
  });

  const distributor_option = distributorList?.result?.map((e: any) => {
    const data = { label: e.name, value: e.id };
    return data;
  });

  const getFilteredData = (key: string) =>
    opetionList?.data?.result
      .filter((e: any) => e.page === "Item Detail Master" && e.key === key)
      .map((e: any) => ({ label: e.description, value: e.name }));

  // const itemStyle = getFilteredData("Item_Style");
  // const itemColor = getFilteredData("Item_Color");
  const itemSize = getFilteredData("Item_Size");

  //filter with enter key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents form submission or unintended behavior

      if (searchTerm.trim() !== "") {
        dispatch(listOrderDetailItemMasterStart(searchTerm));
      } else {
        console.log("Please enter a search term"); // Handle empty input if needed
      }
    }
  };

  //   validation
  const validationSchema = Yup.object().shape({
    id: Yup.number().optional(),
    entrydate: Yup.string().required("Entry Date Required"),

    retail_id: Yup.string().required("Retailer Required"),
    distributor_id: Yup.string().required("Distributor Required"),

    active: Yup.boolean().optional(),
  });

  const formik = useFormik<IOrderDetailMaster>({
    enableReinitialize: true,
    initialValues: {
      entrydate: "",
      retail_id: "",
      distributor_id: "",
      order_detail: [],

      active: initialValues.active ? initialValues.active : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    setValues,
    validateForm,
  } = formik;
  console.log("error", errors);

  const submit = (a: IOrderDetailMaster) => {
    // console.log("the value", a);

    if (a.id) {
      dispatch(
        editOrderDetailMasterStart({
          ...a,
          order_detail: a.order_detail?.flatMap((val) => val.qty_sizes),
        })
      );
    } else {
      dispatch(
        createOrderDetailMasterStart({
          ...a,
          order_detail: a.order_detail?.flatMap((val) => val.qty_sizes),
        })
      );
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      setValues(initialValues);
    }
    return () => {
      setInitialValues({});
      dispatch(clearListOrderDetailItemMaster());
    };
  }, []);

  useEffect(() => {
    if (itemList?.result?.length && itemList?.status) {
      const getId = values.order_detail?.find(
        (val) =>
          val.style === itemList?.result![0].style &&
          val.color === itemList?.result[0].color
      );

      if (getId?.id) {
        // setExistId(itemList?.result[0].id);
        setExistId(itemList?.result[0].style + "_" + itemList?.result[0].color);
      } else {
        setExistId(undefined);
        setSearchTerm("");
        ArrayHelperRef.current?.push({
          id: itemList.result[0].id,
          product: itemList.result[0].product,
          style: itemList.result[0].style,
          qty_sizes: itemList.result,
          color: itemList.result[0].color,
        });
      }
    } else {
      setExistId(undefined);
    }
  }, [itemList]);

  // console.log("ids", existId, values?.order_detail);
  console.log("ids", existId, values?.order_detail?.[0]?.item_id ?? "");

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column">
          <div className="row mt-4">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Retailer Type <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  retailer_option?.find((e: any) => {
                    return e.value === values.retail_id;
                  }) ?? null
                }
                options={retailer_option ?? []}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("retail_id", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.retail_id && errors.retail_id ? true : false}
                    helperText={
                      touched.retail_id && errors.retail_id
                        ? errors.retail_id
                        : ""
                    }
                    name="retail_id"
                    placeholder="Select Retailer Type"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Entry Date<span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                name="entrydate"
                type="date"
                value={
                  values.entrydate
                    ? new Date(values.entrydate).toLocaleDateString("en-CA")
                    : ""
                }
                onBlur={handleBlur}
                error={touched.entrydate && errors.entrydate ? true : false}
                helperText={
                  touched.entrydate && errors.entrydate ? errors.entrydate : ""
                }
                onChange={(e) => {
                  setFieldValue("entrydate", e.target.value);
                }}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Distributor Type <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  distributor_option?.find((e: any) => {
                    return e.value === values.distributor_id;
                  }) ?? null
                }
                options={distributor_option ?? []}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("distributor_id", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.distributor_id && errors.distributor_id
                        ? true
                        : false
                    }
                    helperText={
                      touched.distributor_id && errors.distributor_id
                        ? errors.distributor_id
                        : ""
                    }
                    name="distributor_id"
                    placeholder="Select Distributor"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="col-12 mt-4">
              <div className="col-6 d-flex ms-3 align-items-center">
                {/* <FormControlLabel
                  value={values.active}
                  control={<Checkbox checked={values.active} />}
                  label="Is Active"
                  onChange={(_, val) => {
                    setFieldValue("active", val);
                  }}
                  name="active"
                  labelPlacement="end"
                  sx={{
                    fontWeight: "700",
                  }}
                /> */}
              </div>
            </div>
            <div
              className="ms-auto me-2 d-flex align-items-center mt-4"
              style={{ justifyContent: "flex-start", paddingLeft: "45px" }}
            >
              <TextField
                placeholder="Scan"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
              ></TextField>
              {/* <div
                className="mx-4 add-branch"
                onClick={() => {
                  ArrayHelperRef.current?.push({});
                }}
              >
                Add Items
              </div> */}
            </div>
            <div className=" mt-4" style={{ width: "94%", margin: "0 3%" }}>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell style={{ paddingLeft: "40px" }}>
                        Item
                      </TableCell>
                      <TableCell align="center">Style_Color</TableCell>
                      <TableCell align="center">Quantity Of Sizes</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FormikProvider value={formik}>
                      <FieldArray name="order_detail">
                        {(arrayHelpers) => {
                          ArrayHelperRef.current = arrayHelpers;
                          return values.order_detail &&
                            values.order_detail.length > 0
                            ? values.order_detail.map((row, index) => (
                                <TableRow
                                  key={row?.id}
                                  style={{ border: "1px solid #e0e0e0" }}
                                  sx={{
                                    bgcolor:
                                      existId === row.style + "_" + row.color
                                        ? "#46a4e3"
                                        : "transparent",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "0 16px",
                                    }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0 16px",
                                    }}
                                  >
                                    <TextField
                                      sx={{
                                        "& fieldset": {
                                          border: "none",
                                          alignContent: "center",
                                        },
                                        "& input": {
                                          textOverflow: "ellipsis !important",
                                          overflow: "hidden !important",
                                          whiteSpace: "nowrap",
                                        },
                                      }}
                                      autoComplete="off"
                                      value={row?.product}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `order_detail.${index}.product`,
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      name={`order_detail.${index}.product`}
                                      placeholder="Item"
                                      InputProps={{ readOnly: true }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0 16px",
                                    }}
                                  >
                                    <TextField
                                      title={row?.style + "_" + row?.color}
                                      sx={{
                                        "& fieldset": {
                                          border: "none",
                                          alignContent: "center",
                                        },
                                        "& input": {
                                          textOverflow: "ellipsis !important",
                                          overflow: "hidden !important",
                                          whiteSpace: "nowrap",
                                        },
                                      }}
                                      autoComplete="off"
                                      value={row?.style + "_" + row?.color}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `order_detail.${index}.style`,
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      name={`order_detail.${index}.style`}
                                      placeholder="Style"
                                      InputProps={{ readOnly: true }}
                                    />
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{
                                      padding: "0 16px",
                                      display: "flex",
                                      borderBottom: "none",
                                    }}
                                  >
                                    {row?.qty_sizes?.map((size, sizeIndex) => (
                                      <TextField
                                        sx={{
                                          "& fieldset": {
                                            border: "none",
                                            alignContent: "center",
                                          },
                                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                              WebkitAppearance: "none",
                                              margin: 0,
                                            },
                                          "& input[type=number]": {
                                            MozAppearance: "textfield",
                                          },
                                        }}
                                        style={{
                                          WebkitAppearance: "none",
                                        }}
                                        autoComplete="off"
                                        value={size?.qty}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `order_detail.${index}.qty_sizes.${sizeIndex}.qty`,
                                            e.target.value
                                          );
                                        }}
                                        type="number"
                                        placeholder={size?.size}
                                      />
                                    ))}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      padding: "0 16px",
                                      "& fieldset": {
                                        border: "none",
                                      },
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="mx-2"
                                      style={{
                                        fontSize: "12px",
                                        cursor: "pointer",
                                      }}
                                      icon={["fas", "trash"]}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))
                            : "";
                        }}
                      </FieldArray>
                    </FormikProvider>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="col-12 d-flex justify-content-center">
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  onClick={() => {
                    handleClose();
                    setEdit(false);
                  }}
                  className="cancel"
                >
                  Cancel
                </div>
              </div>
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  className="add"
                  onClick={() => {
                    handleSubmit();
                    validateForm();
                  }}
                >
                  {createOrderDetailLoading || editOrderDetailLoading
                    ? "Please Wait..."
                    : "Save"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompletePopup
          data={{ data: createOrderDetail }}
          responsePopup={Object.keys(createOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateOrderDetailMaster());
            createOrderDetail?.status && handleClose();
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editOrderDetail }}
          responsePopup={Object.keys(editOrderDetail ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditOrderDetailMaster());
            editOrderDetail?.status && handleClose();
            setResponseEdit(false);
          }}
        />
      </form>
    </>
  );
};

export default OrderDetailMasterForm;
