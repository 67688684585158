import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IItemDetailMaster } from "../../pages/itemDetailMaster/IItemDetailMaster";

const CreateItemDetailMaster = createSlice({
  name: "CreateItemDetailMaster",
  initialState: {} as IReduxState<IItemDetailMaster[]>,
  reducers: {
    createItemDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateItemDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  createItemDetailMasterStart,
  successCreateItemDetailMaster,
  failureCreateItemDetailMaster,
  clearCreateItemDetailMaster,
} = CreateItemDetailMaster.actions;

export default CreateItemDetailMaster.reducer;
