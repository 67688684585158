import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  IDistributorMaster,
  IDistributorMasterProps,
} from "./IDistributorMaster";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import {
  clearCreateDistributorMaster,
  createDistributorMasterStart,
} from "../../redux/distributor-master/createDistributorMaster";
import {
  clearEditDistributorMaster,
  editDistributorMasterStart,
} from "../../redux/distributor-master/editDistributorMaster";
import CompletePopup from "../components/response-pop-up";
import AutoComplete from "../components/autocomplete-input";
import { Button } from "react-bootstrap";

const DistributorMasterForm: FunctionComponent<IDistributorMasterProps> = (
  props
) => {
  const { handleClose, setInitialValues, initialValues } = props;
  const [isEdit, setEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // const { data: listDistributor, isLoading: listDistributorLoading } = useAppSelector(
  //   (state: any) => state.ListDistributorMaster
  // );

  const stateOpetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createDistributorLoading, data: createDistributor } =
    useAppSelector((state) => state.CreateDistributorMaster);

  const { isLoading: editDistributorLoading, data: editDistributor } =
    useAppSelector((state) => state.EditDistributorMaster);

  const state_option = stateOpetionList?.data?.result
    .filter((e: any) => {
      if (e.page === "state") {
        return e;
      }
    })
    .map((e: any) => {
      const data = { label: e.description, value: e.name };
      return data;
    });

  // const distributor_option = stateOpetionList?.data?.result
  //   .filter((e: any) => {
  //     if (e.page === "Distributor Master Type") {
  //       return e;
  //     }
  //   })
  //   .map((e: any) => {
  //     const data = { label: e.description, value: e.name };
  //     return data;
  //   });

  // console.log("Distributor option", distributor_option);

  //   validation
  const validationSchema = Yup.object().shape({
    id: Yup.number().optional(),
    name: Yup.string()
      .max(50, "maximum 50 character")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Distributor Name Required"),

    code: Yup.string().required("Code Number Required"),
    city: Yup.string()
      .max(100, "maximum 100 character")
      .required("City Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    state: Yup.string().required("State Required"),

    email: Yup.string()
      .email("email is invalid")
      .max(100, "maximum 100 character")
      .required("Email Required"),

    active: Yup.boolean().optional(),
  });

  const formik = useFormik<IDistributorMaster>({
    enableReinitialize: true,
    initialValues: {
      name: "",

      city: "",

      state: "",
      email: "",

      code: "",
      active: initialValues.active ? initialValues.active : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    setValues,
    validateForm,
  } = formik;

  console.log(touched);

  const submit = (a: IDistributorMaster) => {
    if (a.id) {
      dispatch(editDistributorMasterStart(a));
    } else {
      dispatch(createDistributorMasterStart(a));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      setValues(initialValues);
    }
    return () => {
      setInitialValues({});
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column">
          <div className="row">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="name"
                label="Distributor Name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            {/* <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Distributor Type <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                // value={
                //   distributor_option.find((e: any) => {
                //     return e.value === values.type;
                //   }) ?? null
                // }
                options={distributor_option}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("type", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    // error={touched.type && errors.type ? true : false}
                    // helperText={touched.type && errors.type ? errors.type : ""}
                    name="type"
                    placeholder="Select Retailer Type"
                    {...params}
                  />
                )}
              />
            </div> */}
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="email"
                label="Email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            {/* <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="address1"
                label="Address Line1"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="address2"
                label="Address Line2"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                notRequire
              />
            </div> */}
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="city"
                label="City"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                State <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  state_option.find((e: any) => {
                    return e.value === values.state;
                  }) ?? null
                }
                options={state_option}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("state", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.state && errors.state ? true : false}
                    helperText={
                      touched.state && errors.state ? errors.state : ""
                    }
                    name="state"
                    placeholder="Select State"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="code"
                label="Code"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            {/* <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="contact_name"
                label="Contact Name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="contact_number"
                label="Contact Number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
              */}
            {/* <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="comments"
                label="Description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                notRequire
              />
            </div> */}
            <div className="col-12 mt-4">
              <div className="col-6 d-flex ms-3 align-items-center">
                <FormControlLabel
                  value={values.active}
                  control={<Checkbox checked={values.active} />}
                  label="Is Active"
                  onChange={(_, val) => {
                    setFieldValue("active", val);
                  }}
                  name="active"
                  labelPlacement="end"
                  sx={{
                    fontWeight: "700",
                  }}
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  onClick={() => {
                    handleClose();
                    setEdit(false);
                  }}
                  className="cancel"
                >
                  Cancel
                </div>
              </div>
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  className="add"
                  onClick={() => {
                    handleSubmit();
                    validateForm();
                  }}
                >
                  {createDistributorLoading || editDistributorLoading
                    ? "Please Wait..."
                    : "Save"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default DistributorMasterForm;
