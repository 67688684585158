import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IItemDetailMaster } from "../../pages/itemDetailMaster/IItemDetailMaster";

const DeleteItemDetailMaster = createSlice({
  name: "DeleteItemDetailMaster",
  initialState: {} as IReduxState<IItemDetailMaster[]>,
  reducers: {
    deleteItemDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successDeleteItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureDeleteItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearDeleteItemDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteItemDetailMasterStart,
  successDeleteItemDetailMaster,
  failureDeleteItemDetailMaster,
  clearDeleteItemDetailMaster,
} = DeleteItemDetailMaster.actions;

export default DeleteItemDetailMaster.reducer;
