import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IDistributorMaster } from "../../pages/distributor/IDistributorMaster";

const DeleteDistributorMaster = createSlice({
  name: "DeleteDistributorMaster",
  initialState: {} as IReduxState<IDistributorMaster[]>,
  reducers: {
    deleteDistributorMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successDeleteDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureDeleteDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearDeleteDistributorMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteDistributorMasterStart,
  successDeleteDistributorMaster,
  failureDeleteDistributorMaster,
  clearDeleteDistributorMaster,
} = DeleteDistributorMaster.actions;

export default DeleteDistributorMaster.reducer;
