import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderDetailMaster } from "../../pages/orderDetailMaster/IOrderDetailMaster";

const EditOrderDetailMaster = createSlice({
  name: "EditOrderDetailMaster",
  initialState: {} as IReduxState<IOrderDetailMaster[]>,
  reducers: {
    editOrderDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successEditOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureEditOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearEditOrderDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  editOrderDetailMasterStart,
  successEditOrderDetailMaster,
  failureEditOrderDetailMaster,
  clearEditOrderDetailMaster,
} = EditOrderDetailMaster.actions;

export default EditOrderDetailMaster.reducer;
