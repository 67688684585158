import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderDetailMaster } from "../../pages/orderDetailMaster/IOrderDetailMaster";

const CreateOrderDetailMaster = createSlice({
  name: "CreateOrderDetailMaster",
  initialState: {} as IReduxState<IOrderDetailMaster[]>,
  reducers: {
    createOrderDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateOrderDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  createOrderDetailMasterStart,
  successCreateOrderDetailMaster,
  failureCreateOrderDetailMaster,
  clearCreateOrderDetailMaster,
} = CreateOrderDetailMaster.actions;

export default CreateOrderDetailMaster.reducer;
