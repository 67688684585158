import { PayloadAction } from "@reduxjs/toolkit";
import api from "../axios/middleWare";
import { IItemDetailMaster } from "../../pages/itemDetailMaster/IItemDetailMaster";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failureCreateItemDetailMaster,
  successCreateItemDetailMaster,
} from "./createItemDetailMaster";
import {
  failureListItemDetailMaster,
  successListItemDetailMaster,
} from "./listItemDetailMaster";
import {
  failureEditItemDetailMaster,
  successEditItemDetailMaster,
} from "./editItemDetailMaster";
import {
  failureDeleteItemDetailMaster,
  successDeleteItemDetailMaster,
} from "./deleteItemDetailMaster";

// List Item Detail master
const ListItemDetailMasterApi = (action: PayloadAction<IItemDetailMaster>) => {
  return api.get("/api/itemdetail/list", { params: action.payload });
};

export function* ListItemDetailMasterCall(
  action: PayloadAction<IItemDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(ListItemDetailMasterApi, action);
    yield put(successListItemDetailMaster(response.data));
  } catch (error) {
    yield put(failureListItemDetailMaster(error));
  }
}

// Create Item Detail master
const CreateItemDetailMasterApi = (
  action: PayloadAction<IItemDetailMaster>
) => {
  return api.post("/api/itemdetail/create", action.payload);
};

export function* CreateItemDetailMasterCall(
  action: PayloadAction<IItemDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      CreateItemDetailMasterApi,
      action
    );
    yield put(successCreateItemDetailMaster(response.data));
  } catch (error) {
    yield put(failureCreateItemDetailMaster(error));
  }
}

// update Item Detail master
const UpdateItemDetailMasterApi = (
  action: PayloadAction<IItemDetailMaster>
) => {
  return api.patch("/api/itemdetail/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* UpdateItemDetailMasterCall(
  action: PayloadAction<IItemDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      UpdateItemDetailMasterApi,
      action
    );
    yield put(successEditItemDetailMaster(response.data));
  } catch (error) {
    yield put(failureEditItemDetailMaster(error));
  }
}

// Delete Item Detail master
const DeleteItemDetailMasterApi = (
  action: PayloadAction<IItemDetailMaster>
) => {
  return api.delete("/api/itemdetail/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteItemDetailMasterCall(
  action: PayloadAction<IItemDetailMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      DeleteItemDetailMasterApi,
      action
    );
    yield put(successDeleteItemDetailMaster(response.data));
  } catch (error) {
    yield put(failureDeleteItemDetailMaster(error));
  }
}
