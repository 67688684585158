import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IItemDetailMaster } from "../../pages/itemDetailMaster/IItemDetailMaster";

const ListItemDetailMaster = createSlice({
  name: "ListItemDetailMaster",
  initialState: {} as IReduxState<IItemDetailMaster[]>,
  reducers: {
    listItemDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successListItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureListItemDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  listItemDetailMasterStart,
  successListItemDetailMaster,
  failureListItemDetailMaster,
} = ListItemDetailMaster.actions;

export default ListItemDetailMaster.reducer;
