import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IDistributorMaster } from "../../pages/distributor/IDistributorMaster";

const CreateDistributorMaster = createSlice({
  name: "CreateDistributorMaster",
  initialState: {} as IReduxState<IDistributorMaster[]>,
  reducers: {
    createDistributorMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateDistributorMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateDistributorMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  createDistributorMasterStart,
  successCreateDistributorMaster,
  failureCreateDistributorMaster,
  clearCreateDistributorMaster,
} = CreateDistributorMaster.actions;

export default CreateDistributorMaster.reducer;
