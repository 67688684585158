import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createRef,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IItemDetailMaster, IItemDetailMasterProps } from "./IItemDetailMaster";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Button } from "react-bootstrap";
import { createItemDetailMasterStart } from "../../redux/itemdetail-master/createItemDetailMaster";
import { editItemDetailMasterStart } from "../../redux/itemdetail-master/editItemDetailMaster";

const ItemDetailMasterForm: FunctionComponent<IItemDetailMasterProps> = (
  props
) => {
  const { handleClose, setInitialValues, initialValues } = props;
  const [isEdit, setEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const opetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createItemDetailLoading, data: createItemDetail } =
    useAppSelector((state) => state.CreateItemDetailMaster);

  const { isLoading: editItemDetailLoading, data: editItemDetail } =
    useAppSelector((state) => state.EditItemDetailMaster);

  const getFilteredData = (key: string) =>
    opetionList?.data?.result
      .filter((e: any) => e.page === "Item Detail Master" && e.key === key)
      .map((e: any) => ({ label: e.name, value: e.name }));

  const itemStyle = getFilteredData("Item_Style");
  const itemColor = getFilteredData("Item_Color");
  const itemSize = getFilteredData("Item_Size");

  //   validation
  const validationSchema = Yup.object().shape({
    id: Yup.number().optional(),
    product: Yup.string().required("Product Name Required"),
    barcode: Yup.string().required("Barcode Required"),
    style: Yup.string().required("Style Required"),
    color: Yup.string().required("Color Required"),
    size: Yup.string().required("Size Required"),
    order: Yup.string().required("Order Required"),
    mrp: Yup.string().required("MRP Required"),
    active: Yup.boolean().optional(),
  });

  const formik = useFormik<IItemDetailMaster>({
    enableReinitialize: true,
    initialValues: {
      product: "",
      barcode: "",
      style: "",
      color: "",
      size: "",
      order: "",
      mrp: "",
      active: initialValues.active ? initialValues.active : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    setValues,
    validateForm,
  } = formik;

  console.log(touched);

  const submit = (a: IItemDetailMaster) => {
    if (a.id) {
      dispatch(editItemDetailMasterStart(a));
    } else {
      dispatch(createItemDetailMasterStart(a));
    }
  };

  useEffect(() => {
    if (initialValues?.id) {
      setValues(initialValues);
    }
    return () => {
      setInitialValues({});
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column">
          <div className="row">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                autoComplete="off"
                name="product"
                label="Product Name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>

            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="barcode"
                label="Barcode"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Style <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  itemStyle.find((e: any) => {
                    return e.value === values.style;
                  }) ?? null
                }
                options={itemStyle}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("style", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.style && errors.style ? true : false}
                    helperText={
                      touched.style && errors.style ? errors.style : ""
                    }
                    name="style"
                    placeholder="Select Style"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Color <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  itemColor.find((e: any) => {
                    return e.value === values.color;
                  }) ?? null
                }
                options={itemColor}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("color", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.color && errors.color ? true : false}
                    helperText={
                      touched.color && errors.color ? errors.color : ""
                    }
                    name="color"
                    placeholder="Select Color"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label style={{ display: "flex", padding: "0 15px" }}>
                Size <span style={{ marginLeft: "3px" }}>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    display: "flex",
                    alignContent: "center",
                  },

                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  padding: "0 14px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={
                  itemSize.find((e: any) => {
                    return e.value === values.size;
                  }) ?? null
                }
                options={itemSize}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  setFieldValue("size", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.size && errors.size ? true : false}
                    helperText={touched.size && errors.size ? errors.size : ""}
                    name="size"
                    placeholder="Select Color"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="order"
                label="Sort Order"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <TextFiealdInput
                name="mrp"
                label="MRP"
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-12 mt-4">
              <div className="col-6 d-flex ms-3 align-items-center">
                <FormControlLabel
                  value={values.active}
                  control={<Checkbox checked={values.active} />}
                  label="Is Active"
                  onChange={(_, val) => {
                    setFieldValue("active", val);
                  }}
                  name="active"
                  labelPlacement="end"
                  sx={{
                    fontWeight: "700",
                  }}
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="add-more px-3 col-3 d-flex flex-column">
                <div
                  onClick={() => {
                    handleClose();
                    setEdit(false);
                  }}
                  className="cancel"
                >
                  Cancel
                </div>
              </div>
              <div className="add-more px-3 col-3 d-flex flex-column">
                {/* <Button
                  className="add"
                  type="submit"
                  style={{ borderRadius: "inherit" }}
                  onClick={() => {
                    validateForm();
                  }}
                >
                  {createItemDetailLoading || editItemDetailLoading
                    ? "Please Wait..."
                    : "Save"}
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ItemDetailMasterForm;
