import { Backdrop, InputAdornment, TextField } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { useSearchParams } from "react-router-dom";
import { IItemDetailMaster } from "./IItemDetailMaster";

import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import ItemDetailMasterList from "./ItemDetailMasterList";
import { clearCreateItemDetailMaster } from "../../redux/itemdetail-master/createItemDetailMaster";
import { clearEditItemDetailMaster } from "../../redux/itemdetail-master/editItemDetailMaster";
import { listItemDetailMasterStart } from "../../redux/itemdetail-master/listItemDetailMaster";

const ItemDetailMaster: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemDetail, setItemDetail] = useState<IItemDetailMaster>({});
  const [search, setSearch] = useState<string>("");

  const dispatch = useAppDispatch();
  const { data: itemDetailList, isLoading: listItemDetailLoading } =
    useAppSelector((state) => state.ListItemDetailMaster);
  const { isLoading: createItemDetailLoading, data: createItemDetail } =
    useAppSelector((state) => state.CreateItemDetailMaster);

  const { isLoading: editItemDetailLoading, data: editItemDetail } =
    useAppSelector((state) => state.EditItemDetailMaster);
  const listItemDetail =
    itemDetailList?.result?.map((itemDetail, index) => ({
      ...itemDetail,
      index: index + 1,
    })) ?? [];

  // list render
  useEffect(() => {
    dispatch(listItemDetailMasterStart(null));
  }, [createItemDetail, editItemDetail]);

  //clear create edit data and get the state list
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    return () => {
      dispatch(clearCreateItemDetailMaster());
      dispatch(clearEditItemDetailMaster());
    };
  }, []);

  useEffect(() => {
    if (listItemDetail?.length !== 0) {
      const itemDetailCheckById =
        searchParams.get("id") &&
        listItemDetail?.find(
          (item) => item.id === Number(searchParams.get("id"))
        );

      if (itemDetailCheckById) {
        setItemDetail(itemDetailCheckById);
      } else {
        // setItemDetail(listItemDetail[0]!);
        setSearchParams({ id: listItemDetail[0]?.id?.toString() ?? "" });
      }
    }
  }, [
    searchParams.get("id"),
    listItemDetail?.length,
    createItemDetail,
    editItemDetail,
  ]);

  return (
    <div className="d-flex">
      <>
        <div className="list col-5 ">
          <div className=" px-3 my-4  d-flex align-items-center">
            <div className="title d-flex ">
              <h5>Item Detail Master</h5>
            </div>

            <div className="filter ms-auto me-3 d-flex align-items-center">
              <TextField
                placeholder="Search"
                variant="outlined"
                className="w-100 me-2"
                name="search"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "0px",
                    height: "45px",
                  },
                }}
              />
              <FontAwesomeIcon
                onClick={() => {
                  dispatch(listItemDetailMasterStart(null));
                }}
                className="mx-2"
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                icon={["fas", "arrows-rotate"]}
              />
            </div>
          </div>
          <div
            className="list"
            style={{
              overflow: "scroll",
              height: "80vh",
              background: "#fafafa",
            }}
          >
            <>
              {listItemDetail &&
                listItemDetail
                  .filter((item) => {
                    return Object.values(item)
                      .join("")
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .map((val, key) => (
                    <>
                      <div
                        key={key + 1}
                        id={itemDetail.id === val.id ? "active-lists" : ""}
                        className={"d-flex lists flex-column mt-2  p-3"}
                        style={{
                          height: "100px",
                          background: "#FFFFFF",
                        }}
                        onClick={() => {
                          val.id && setSearchParams({ id: val.id?.toString() });
                        }}
                      >
                        <div className="mx-2 partner-name">{val.product}</div>
                        <div className="d-flex mt-auto partner-locations align-items-center">
                          <p className="ms-2 me-auto mb-0">{val.barcode}</p>
                          <p className={"ms-auto me-2 mb-0 text-yellow"}>
                            {val.order}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
            </>
          </div>
        </div>
      </>
      <>
        <ItemDetailMasterList itemDetail={itemDetail} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!listItemDetailLoading}
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
      </>
    </div>
  );
};

export default ItemDetailMaster;
