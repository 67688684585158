import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IRetailerMaster } from "../../pages/retailer-master/IRetailerMaster";

const ListRetailerMaster = createSlice({
  name: "ListRetailerMaster",
  initialState: {} as IReduxState<IRetailerMaster[]>,
  reducers: {
    listRetailerMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successListRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureListRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  listRetailerMasterStart,
  successListRetailerMaster,
  failureListRetailerMaster,
} = ListRetailerMaster.actions;

export default ListRetailerMaster.reducer;
