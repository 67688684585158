import { Backdrop, InputAdornment, TextField } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { listRetailerMasterStart } from "../../redux/retail-master/listRetailerMaster";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { useSearchParams } from "react-router-dom";
import { IDistributorMaster } from "./IDistributorMaster";
import DistributorMasterList from "./DistributorMasterList";
import { clearCreateDistributorMaster } from "../../redux/distributor-master/createDistributorMaster";
import { clearEditDistributorMaster } from "../../redux/distributor-master/editDistributorMaster";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import { listDistributorMasterStart } from "../../redux/distributor-master/listDistributorMaster";

const DistributorMaster: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [distributor, setDistributor] = useState<IDistributorMaster>({});
  const [search, setSearch] = useState<string>("");

  const dispatch = useAppDispatch();
  const { data: distributorList, isLoading: listDistributorLoading } =
    useAppSelector((state) => state.ListDistributorMaster);
  const { isLoading: createReatilerLoading, data: createDistributor } =
    useAppSelector((state) => state.CreateDistributorMaster);

  const { isLoading: editdistributorLoading, data: editDistributor } =
    useAppSelector((state) => state.EditDistributorMaster);
  const ListDistributor =
    distributorList?.result?.map((distributor, index) => ({
      ...distributor,
      index: index + 1,
    })) ?? [];

  // list render
  useEffect(() => {
    dispatch(listDistributorMasterStart(null));
  }, [createDistributor, editDistributor]);

  //clear create edit data and get the state list
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    return () => {
      dispatch(clearCreateDistributorMaster());
      dispatch(clearEditDistributorMaster());
    };
  }, []);

  useEffect(() => {
    if (ListDistributor?.length !== 0) {
      const distributorCheckById =
        searchParams.get("id") &&
        ListDistributor?.find(
          (distributor) => distributor.id === Number(searchParams.get("id"))
        );

      if (distributorCheckById) {
        setDistributor(distributorCheckById);
      } else {
        // setdistributor(ListRetailer[0]!);
        setSearchParams({ id: ListDistributor[0]?.id?.toString() ?? "" });
      }
    }
  }, [
    searchParams.get("id"),
    ListDistributor?.length,
    createDistributor,
    editDistributor,
  ]);

  return (
    <div className="d-flex">
      <>
        <div className="list col-5 ">
          <div className=" px-3 my-4  d-flex align-items-center">
            <div className="title d-flex ">
              <h5>Distributor Master</h5>
            </div>

            <div className="filter ms-auto me-3 d-flex align-items-center">
              <TextField
                placeholder="Search"
                variant="outlined"
                className="w-100 me-2"
                name="search"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "0px",
                    height: "45px",
                  },
                }}
              />
              <FontAwesomeIcon
                onClick={() => {
                  dispatch(listDistributorMasterStart(null));
                }}
                className="mx-2"
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                icon={["fas", "arrows-rotate"]}
              />
            </div>
          </div>
          <div
            className="list"
            style={{
              overflow: "scroll",
              height: "80vh",
              background: "#fafafa",
            }}
          >
            <>
              {ListDistributor &&
                ListDistributor.filter((item) => {
                  return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(search.toLowerCase());
                }).map((val, key) => (
                  <>
                    <div
                      key={key + 1}
                      id={distributor.id === val.id ? "active-lists" : ""}
                      className={"d-flex lists flex-column mt-2  p-3"}
                      style={{
                        height: "100px",
                        background: "#FFFFFF",
                      }}
                      onClick={() => {
                        val.id && setSearchParams({ id: val.id?.toString() });
                      }}
                    >
                      <div className="mx-2 partner-name">{val.name}</div>
                      <div className="d-flex mt-auto partner-locations align-items-center">
                        <p className="ms-2 me-auto mb-0">{val.code}</p>
                        <p className={"ms-auto me-2 mb-0 text-yellow"}>
                          {val.active ? "Active" : "InActive"}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </>
          </div>
        </div>
      </>
      <>
        <DistributorMasterList distributor={distributor} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!listDistributorLoading}
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
      </>
    </div>
  );
};

export default DistributorMaster;
