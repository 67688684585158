import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderDetailMaster } from "../../pages/orderDetailMaster/IOrderDetailMaster";

const DeleteOrderDetailMaster = createSlice({
  name: "DeleteOrderDetailMaster",
  initialState: {} as IReduxState<IOrderDetailMaster[]>,
  reducers: {
    deleteOrderDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successDeleteOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureDeleteOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearDeleteOrderDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteOrderDetailMasterStart,
  successDeleteOrderDetailMaster,
  failureDeleteOrderDetailMaster,
  clearDeleteOrderDetailMaster,
} = DeleteOrderDetailMaster.actions;

export default DeleteOrderDetailMaster.reducer;
