import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IRetailerMaster } from "../../pages/retailer-master/IRetailerMaster";

const DeleteRetailerMaster = createSlice({
  name: "DeleteRetailerMaster",
  initialState: {} as IReduxState<IRetailerMaster[]>,
  reducers: {
    deleteRetailerMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successDeleteRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureDeleteRetailerMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearDeleteRetailerMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteRetailerMasterStart,
  successDeleteRetailerMaster,
  failureDeleteRetailerMaster,
  clearDeleteRetailerMaster,
} = DeleteRetailerMaster.actions;

export default DeleteRetailerMaster.reducer;
