import { call, takeEvery, put } from "redux-saga/effects";
import {
  CheckUserApi,
  LoginApi,
  MenuApi,
} from "./login-and-create-password/login-api";
import { CreatePasswordApi } from "./login-and-create-password/create-password-api";
import { AddGeneralSettingApi } from "./general-setting/add-general-setting-api";
import { listGeneralSettingApi } from "./general-setting/list-general-setting-api";
import { getAggrementOtp } from "./business-partner/onboarding/get-aggrement-otp-api";
import { AddOnboardingApi } from "./business-partner/onboarding/add-onboarding-api-service";
import { listOnboardingApi } from "./business-partner/onboarding/list-add-onboarding";
import { EditGeneralSettingApi } from "./general-setting/edit-general-setiing-api";

import {
  ApproveManagementPartnerMasterApi,
  listPartnerMasterApi,
  createPartnerMasterApi,
  listOnePartnerMasterApi,
  RequestClarificationApi,
  listOnePartnerDetailsApi,
  updatePartnerMasterApi,
} from "./business-partner/partner-master/partner-master-api-service";
import {
  ListNotificationApi,
  ReadOneNotificationApi,
} from "./notification/notification-api-service";
import {
  ListBankMaster,
  ListWTAXMaster,
  CustomerReceivableAccountApi,
  ListDownPayAccount,
  GroupMasterListApiCall,
  IFSCListApiCall,
  VendorPayListApiCall,
  SinkBankMaster,
  SinkDownPayMaster,
  SinkWtaxMaster,
  SinkVendorMaster,
  SinkCustomerMaster,
  SinkGroupMaster,
} from "./sap-master/sap-master.-api-service";

import {
  AddBranchMaster,
  ListAgreementMaster,
  AddAgreementMaster,
  ListBranchMaster,
  EditBusinessChannelApi,
  AddBusinessChannelApi,
  ListBusinessChannel,
  EditBranchMasterApi,
  EditAgreementMaster,
  AddStateMasterApi,
  ListStateMasterApi,
  editStateMasterApi,
  ListOneAgreementMaster,
} from "./prefered-master/prefered-master-api-service";
import { updateAccountsOnboardingApi } from "./business-partner/onboarding/update-acounts-onboarding";
import { getAgremeent } from "./business-partner/onboarding/get-agreements";
import { VerifyOtpOnboardingApi } from "./business-partner/onboarding/verify-otp-reducer";
import { getCostCenter } from "./business-partner/onboarding/get-ar-andhd-locations";
import { getConsalidationBusinessPartner } from "./business-partner/onboarding/list-consalidation-business-partner";
import {
  listRoleApi,
  AddUserApi,
  listUserApi,
  editUserApi,
} from "./add-user/add-user-api";
import { EditOnboardingApi } from "./business-partner/onboarding/onboarding-edit-api";
import { SubmitClarificationApi } from "./business-partner/onboarding/clarification-submited";
import { getAgreementAvailable } from "./business-partner/onboarding/check-agreement-valid-or-not";
import { ConvertSapApi } from "./business-partner/partner-master/move-xml-data";
import {
  CreateRetailerMasterCall,
  DeleteRetailerMasterCall,
  ListRetailerMasterCall,
  UpdateRetailerMasterCall,
} from "./retail-master/ApiRetailMaster";

import {
  CreateItemDetailMasterCall,
  DeleteItemDetailMasterCall,
  ListItemDetailMasterCall,
  UpdateItemDetailMasterCall,
} from "./itemdetail-master/ApiItemDetailMaster";
import {
  CreateOrderDetailMasterCall,
  DeleteOrderDetailMasterCall,
  ListOrderDetailItemMasterCall,
  ListOrderDetailMasterCall,
  UpdateOrderDetailMasterCall,
} from "./orderdetail-master/ApiOrderDetailMaster";
import {
  CreateDistributorMasterCall,
  DeleteDistributorMasterCall,
  ListDistributorMasterCall,
  UpdateDistributorMasterCall,
} from "./distributor-master/ApiDistributorMaster";

export default function* rootSaga() {
  yield takeEvery("PartnerMasterToSap/convert_sap_from_api", ConvertSapApi);
  yield takeEvery("Login/raise_api_call", LoginApi);
  yield takeEvery(
    "CreatePassword/raise_api_call_create_password",
    CreatePasswordApi
  );
  yield takeEvery(
    "ListOneAgreementMaster/list_one_agreement_master",
    ListOneAgreementMaster
  );
  yield takeEvery(
    "CheckAgreementAvailable/test_agreement_available_from_api",
    getAgreementAvailable
  );
  yield takeEvery(
    "AddGeneralSetting/raise_api_call_add_general_setting",
    AddGeneralSettingApi
  );
  yield takeEvery(
    "listGeneralSetting/raise_api_call_list_general_setting",
    listGeneralSettingApi
  );
  yield takeEvery(
    "getOnboardingAggrementOtp/get_otp_from_api",
    getAggrementOtp
  );
  yield takeEvery(
    "onboardingAddBasicInfo/raise_api_call_add_basicInfo",
    AddOnboardingApi
  );
  yield takeEvery(
    "onboardingEditBasicInfo/raise_api_call_edit_basic_info",
    EditOnboardingApi
  );
  yield takeEvery(
    "onboardingBasicInfo/raise_api_for_list_onboarding",
    listOnboardingApi
  );
  yield takeEvery(
    "EditGeneralSetting/raise_api_call_edit_general_setting",
    EditGeneralSettingApi
  );
  yield takeEvery(
    "listOnePartnerDetail/raise_api_call_list_partner_one_detail",
    listOnePartnerDetailsApi
  );
  yield takeEvery(
    "listPartnerMaster/raise_api_call_list_partner_master",
    listPartnerMasterApi
  );
  yield takeEvery(
    "CreatePartnerDetails/raise_api_call_Create_partner",
    createPartnerMasterApi
  );
  yield takeEvery(
    "UpdatePartnerDetails/raise_api_call_Update_partner",
    updatePartnerMasterApi
  );
  yield takeEvery("ListNotification/list_notification", ListNotificationApi);
  yield takeEvery(
    "ReadOneNotification/read_one_notification",
    ReadOneNotificationApi
  );
  yield takeEvery(
    "ApprovePartner/management_approve_partner",
    ApproveManagementPartnerMasterApi
  );
  yield takeEvery("WTAXMasterListApi/list_wtax_master", ListWTAXMaster);
  yield takeEvery("BankMasterListApi/list_bank_master", ListBankMaster);
  yield takeEvery("BankIFSCCodeListApi/list_bank_Ifsc_code", IFSCListApiCall);
  yield takeEvery("DownPayAccount/list_down_pay_account", ListDownPayAccount);
  yield takeEvery(
    "GenerateCostCentre/generate_cost_center_from_api",
    getCostCenter
  );
  yield takeEvery(
    "CustomerReceivable/list_customer_receivable",
    CustomerReceivableAccountApi
  );
  yield takeEvery("ListBranchMaster/list_branch_master", ListBranchMaster);
  yield takeEvery("AddBranchMaster/add_branch_master", AddBranchMaster);
  yield takeEvery("GroupMasterList/list_group_master", GroupMasterListApiCall);
  yield takeEvery("AgreementMaster/list_agreement_master", ListAgreementMaster);
  yield takeEvery(
    "AddAgreementMaster/add_agreement_master",
    AddAgreementMaster
  );
  yield takeEvery("VendorPayListApi/list_vendor_pay", VendorPayListApiCall);
  yield takeEvery(
    "ListConsalidationBusinessPartner/get_consalidation_business_partner",
    getConsalidationBusinessPartner
  );
  yield takeEvery(
    "getOnboardingAggrement/get_agreement_from_api",
    getAgremeent
  );
  yield takeEvery(
    "UpdateAcountsOnboarding/raise_api_call_update_acounts_onboarding",
    updateAccountsOnboardingApi
  );
  yield takeEvery(
    "VerifyOtpOnboarding/raise_api_call_verify_otp_onboarding",
    VerifyOtpOnboardingApi
  );
  yield takeEvery(
    "BusinessChannelList/list_business_channel",
    ListBusinessChannel
  );
  yield takeEvery(
    "AddBusinessBhannel/add_business_channel",
    AddBusinessChannelApi
  );
  yield takeEvery(
    "SinkDownPayAccountMaster/sink_down_pay_account_master",
    SinkDownPayMaster
  );
  yield takeEvery("SinkbankMaster/sink_bank_master", SinkBankMaster);
  yield takeEvery(
    "listOnePartnerMaster/raise_api_call_list_partner_one_master",
    listOnePartnerMasterApi
  );
  yield takeEvery(
    "RequestClarification/request_clarification",
    RequestClarificationApi
  );
  yield takeEvery("editUser/edit_user", editUserApi);
  yield takeEvery("EditBranchMaster/edit_branch_master", EditBranchMasterApi);
  yield takeEvery(
    "EditAgreementMaster/edit_agreement_master",
    EditAgreementMaster
  );
  yield takeEvery(
    "SubmitClarification/submit_clarification",
    SubmitClarificationApi
  );
  yield takeEvery("EditStateMaster/edit_state_master", editStateMasterApi);
  yield takeEvery("AddStateMaster/add_state_master", AddStateMasterApi);
  yield takeEvery("StateMasterList/list_state_master", ListStateMasterApi);
  yield takeEvery("AddUser/add_user", AddUserApi);
  yield takeEvery("Menus/raise_api_call_menu", MenuApi);
  yield takeEvery("ListUser/list_user", listUserApi);
  yield takeEvery("CheckUserExist/raise_api_call_check_user", CheckUserApi);
  yield takeEvery("ListRole/list_role", listRoleApi);
  yield takeEvery("SinkwtaxMaster/sink_wtax_master", SinkWtaxMaster);
  yield takeEvery("SinkVendorMaster/sink_vendor_master", SinkVendorMaster);
  yield takeEvery("SinkGroupMaster/sink_group_account_master", SinkGroupMaster);
  yield takeEvery(
    "EditBusinessBhannel/edit_business_channel",
    EditBusinessChannelApi
  );
  yield takeEvery(
    "SinkCustomerReceivableMaster/sink_customer_receivable_master",
    SinkCustomerMaster
  );

  //Retailer Master
  yield takeEvery(
    "CreateRetailerMaster/createRetailerMasterStart",
    CreateRetailerMasterCall
  );
  yield takeEvery(
    "ListRetailerMaster/listRetailerMasterStart",
    ListRetailerMasterCall
  );
  yield takeEvery(
    "EditRetailerMaster/editRetailerMasterStart",
    UpdateRetailerMasterCall
  );
  yield takeEvery(
    "DeleteRetailerMaster/deleteRetailerMasterStart",
    DeleteRetailerMasterCall
  );

  //ItemDetail Master
  yield takeEvery(
    "CreateItemDetailMaster/createItemDetailMasterStart",
    CreateItemDetailMasterCall
  );
  yield takeEvery(
    "ListItemDetailMaster/listItemDetailMasterStart",
    ListItemDetailMasterCall
  );
  yield takeEvery(
    "EditItemDetailMaster/editItemDetailMasterStart",
    UpdateItemDetailMasterCall
  );
  yield takeEvery(
    "DeleteItemDetailMaster/deleteItemDetailMasterStart",
    DeleteItemDetailMasterCall
  );

  //OrderDetail Master
  yield takeEvery(
    "CreateOrderDetailMaster/createOrderDetailMasterStart",
    CreateOrderDetailMasterCall
  );
  yield takeEvery(
    "ListOrderDetailMaster/listOrderDetailMasterStart",
    ListOrderDetailMasterCall
  );
  yield takeEvery(
    "EditOrderDetailMaster/editOrderDetailMasterStart",
    UpdateOrderDetailMasterCall
  );
  yield takeEvery(
    "DeleteOrderDetailMaster/deleteOrderDetailMasterStart",
    DeleteOrderDetailMasterCall
  );

  yield takeEvery(
    "ListOrderDetailItemMaster/listOrderDetailItemMasterStart",
    ListOrderDetailItemMasterCall
  );

  //Distributor Master
  yield takeEvery(
    "CreateDistributorMaster/createDistributorMasterStart",
    CreateDistributorMasterCall
  );
  yield takeEvery(
    "ListDistributorMaster/listDistributorMasterStart",
    ListDistributorMasterCall
  );
  yield takeEvery(
    "EditDistributorMaster/editDistributorMasterStart",
    UpdateDistributorMasterCall
  );
  yield takeEvery(
    "DeleteDistributorMaster/deleteDistributorMasterStart",
    DeleteDistributorMasterCall
  );
}
