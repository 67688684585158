import { PayloadAction } from "@reduxjs/toolkit";
import api from "../axios/middleWare";
import { IDistributorMaster } from "../../pages/distributor/IDistributorMaster";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failureCreateDistributorMaster,
  successCreateDistributorMaster,
} from "./createDistributorMaster";
import {
  failureListDistributorMaster,
  successListDistributorMaster,
} from "./listDistributorMaster";
import {
  failureEditDistributorMaster,
  successEditDistributorMaster,
} from "./editDistributorMaster";
import {
  failureDeleteDistributorMaster,
  successDeleteDistributorMaster,
} from "./deleteDistributorMaster";

// List Retail master
const ListDistributorMasterApi = (
  action: PayloadAction<IDistributorMaster>
) => {
  return api.get("/api/distributor/list", { params: action.payload });
};

export function* ListDistributorMasterCall(
  action: PayloadAction<IDistributorMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      ListDistributorMasterApi,
      action
    );
    yield put(successListDistributorMaster(response.data));
  } catch (error) {
    yield put(failureListDistributorMaster(error));
  }
}

// Create Retail master
const CreateDistributorMasterApi = (
  action: PayloadAction<IDistributorMaster>
) => {
  return api.post("/api/distributor/create", action.payload);
};

export function* CreateDistributorMasterCall(
  action: PayloadAction<IDistributorMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      CreateDistributorMasterApi,
      action
    );
    yield put(successCreateDistributorMaster(response.data));
  } catch (error) {
    yield put(failureCreateDistributorMaster(error));
  }
}

// update Retail master
const UpdateDistributorMasterApi = (
  action: PayloadAction<IDistributorMaster>
) => {
  return api.patch("/api/distributor/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* UpdateDistributorMasterCall(
  action: PayloadAction<IDistributorMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      UpdateDistributorMasterApi,
      action
    );
    yield put(successEditDistributorMaster(response.data));
  } catch (error) {
    yield put(failureEditDistributorMaster(error));
  }
}

// Delete Retail master
const DeleteDistributorMasterApi = (
  action: PayloadAction<IDistributorMaster>
) => {
  return api.delete("/api/distributor/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteDistributorMasterCall(
  action: PayloadAction<IDistributorMaster>
) {
  try {
    const response: AxiosResponse = yield call(
      DeleteDistributorMasterApi,
      action
    );
    yield put(successDeleteDistributorMaster(response.data));
  } catch (error) {
    yield put(failureDeleteDistributorMaster(error));
  }
}
