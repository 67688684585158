import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
export const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

const success = async (e: any) => {
  localStorage.setItem("SpApperalsToken", e.token);
  console.log(e.result);
  if (e.result.length != 0) {
    localStorage.setItem("SpApperalsRole", e.result.role_name);
    localStorage.setItem("userName", e.result.name);
  } else {
    localStorage.setItem("SpApperalsRole", "");
  }
};

export const Login = createSlice({
  name: "Login",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    raise_api_call: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      success(action.payload);
    },
    failure_api_call: (state, action) => {
      console.log(action.payload);

      state.data = action.payload;
      state.isLoading = false;
      if (action.payload.status_code != 503) {
        FAILED(action.payload);
      }
    },

    data_cleare: (state, action) => {
      state.data = [];
    },
  },
});
export default Login.reducer;
export const {
  raise_api_call,
  success_api_call,
  failure_api_call,
  data_cleare,
} = Login.actions;
