import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../interfaces/IReduxState";
import { IOrderDetailMaster } from "../../pages/orderDetailMaster/IOrderDetailMaster";
import { FAILED } from "../login-and-create-password/login-reducer";

const ListOrderDetailMaster = createSlice({
  name: "ListOrderDetailMaster",
  initialState: {} as IReduxState<IOrderDetailMaster[]>,
  reducers: {
    listOrderDetailMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successListOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      console.log(action.payload);
    },
    failureListOrderDetailMaster: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearListOrderDetailMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  listOrderDetailMasterStart,
  successListOrderDetailMaster,
  failureListOrderDetailMaster,
  clearListOrderDetailMaster,
} = ListOrderDetailMaster.actions;

export default ListOrderDetailMaster.reducer;
