import { createRef, FunctionComponent, useEffect, useState } from "react";
import {
  IDistributorMaster,
  DistributorMasterListProps,
} from "./IDistributorMaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DistributorMasterForm from "./DistributorMasterForm";
import CompletePopup from "../components/response-pop-up";
import { clearCreateDistributorMaster } from "../../redux/distributor-master/createDistributorMaster";
import { clearEditDistributorMaster } from "../../redux/distributor-master/editDistributorMaster";

const DistributorMasterList: FunctionComponent<DistributorMasterListProps> = (
  props
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IDistributorMaster>({
    active: true,
  });
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);

  const fileRep = createRef<any>();
  const dispatch = useAppDispatch();

  const stateOpetionList: any = useAppSelector((state) => state.GeneralSetting);

  const { isLoading: createDistributorLoading, data: createDistributor } =
    useAppSelector((state) => state.CreateDistributorMaster);

  const { isLoading: editDistributorLoading, data: editDistributor } =
    useAppSelector((state) => state.EditDistributorMaster);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const { distributor } = props;

  useEffect(() => {
    if (
      Object.keys(editDistributor ?? {}).length ||
      Object.keys(createDistributor ?? {}).length
    ) {
      handleClose();
    }
  }, [editDistributor, createDistributor]);
  return (
    <>
      <div
        className="col-7 tracking pt-4 px-4"
        style={{
          height: "100vh",
        }}
      >
        <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
          <div
            className="mx-4 add-branch"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Distributor
          </div>
          <div>
            <FontAwesomeIcon
              className="mx-2"
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "pen-to-square"]}
              onClick={() => {
                handleClickOpen();
                setInitialValues(distributor ?? {});
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 partner-detail-data mt-4">
            <label>Distributor Name</label>
            <p>{distributor?.name}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>code</label>
            <p>{distributor?.code}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>City</label>
            <p>{distributor?.city}</p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>State</label>
            <p>
              {stateOpetionList?.data?.result?.find((e: any) => {
                return e.name === distributor?.state;
              })?.description ?? null}
            </p>
          </div>
          <div className="col-4 partner-detail-data mt-4">
            <label>Email</label>
            <p>{distributor?.email}</p>
          </div>

          <div className="col-4 partner-detail-data mt-4">
            <label>Status</label>
            <p>{distributor?.active ? "Active" : "InActive"}</p>
          </div>
        </div>
        {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
      </div>
      <>
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Add Distributor"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <DistributorMasterForm
              initialValues={initialValues}
              handleClose={handleClose}
              setInitialValues={setInitialValues}
            />
          </DialogContent>
        </Dialog>

        <CompletePopup
          data={{ data: createDistributor }}
          responsePopup={Object.keys(createDistributor ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearCreateDistributorMaster());
            setResponsePopup(false);
          }}
        />
        <CompletePopup
          data={{ data: editDistributor }}
          responsePopup={Object.keys(editDistributor ?? {}).length}
          setCompletePopup={(e: any) => {
            dispatch(clearEditDistributorMaster());
            setResponseEdit(false);
          }}
        />
      </>
    </>
  );
};

export default DistributorMasterList;
