import { Backdrop, InputAdornment, TextField } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "../../redux/hooks/Hooks";
import { useSearchParams } from "react-router-dom";
import { IOrderDetailMaster, IOrderItemDetail } from "./IOrderDetailMaster";

import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import OrderDetailMasterList from "./OrderDetailMasterList";
import { clearCreateItemDetailMaster } from "../../redux/itemdetail-master/createItemDetailMaster";
import { clearEditItemDetailMaster } from "../../redux/itemdetail-master/editItemDetailMaster";
import { listItemDetailMasterStart } from "../../redux/itemdetail-master/listItemDetailMaster";
import { listOrderDetailMasterStart } from "../../redux/orderdetail-master/listOrderDetailMaster";
import { clearCreateOrderDetailMaster } from "../../redux/orderdetail-master/createOrderDetailMaster";
import { clearEditOrderDetailMaster } from "../../redux/orderdetail-master/editOrderDetailMaster";
import { listRetailerMasterStart } from "../../redux/retail-master/listRetailerMaster";
import OrderDetailMasterForm from "./OrderDetailMasterForm";
import {
  listDistributorMasterStart,
  clearListDistributorMaster,
} from "../../redux/distributor-master/listDistributorMaster";
import { clearListOrderDetailItemMaster } from "../../redux/orderdetail-master/searchOrderDetailItems";

const OrderDetailMaster: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderDetail, setOrderDetail] = useState<IOrderDetailMaster>({});
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IOrderDetailMaster>({
    active: true,
  });
  const [isEdit, setEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data: orderDetailList, isLoading: listOrderDetailLoading } =
    useAppSelector((state) => state.ListOrderDetailMaster);

  const { data: createOrderDetail } = useAppSelector(
    (state) => state.CreateOrderDetailMaster
  );

  const { data: editOrderDetail } = useAppSelector(
    (state) => state.EditOrderDetailMaster
  );
  const listOrderDetail =
    orderDetailList?.result?.map((orderDetail, index) => ({
      ...orderDetail,
      index: index + 1,
    })) ?? [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  // list render
  useEffect(() => {
    dispatch(listOrderDetailMasterStart(null));
  }, [createOrderDetail, editOrderDetail]);

  //clear create edit data and get the state list
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    dispatch(listItemDetailMasterStart(null));
    dispatch(listRetailerMasterStart(null));
    dispatch(listDistributorMasterStart(null));
    return () => {
      dispatch(clearCreateOrderDetailMaster());
      dispatch(clearEditOrderDetailMaster());
      dispatch(clearListDistributorMaster());
      dispatch(clearListOrderDetailItemMaster());
    };
  }, []);

  useEffect(() => {
    if (listOrderDetail?.length !== 0) {
      const orderDetailCheckById =
        searchParams.get("id") &&
        listOrderDetail?.find(
          (item) => item.id === Number(searchParams.get("id"))
        );

      if (orderDetailCheckById) {
        const order_details = orderDetailCheckById.order_detail?.reduce(
          (accumulater: IOrderItemDetail[], currentValue) => {
            const findVal = accumulater.find(
              (data) =>
                data.style === currentValue.style &&
                data.color === currentValue.color
            );
            if (findVal) {
              findVal.qty_sizes?.push(currentValue);
            } else {
              accumulater.push({ ...currentValue, qty_sizes: [currentValue] });
            }
            return accumulater;
          },
          []
        );
        console.log("data", order_details);

        setOrderDetail({
          ...orderDetailCheckById,
          order_detail: order_details,
        });
      } else {
        // setItemDetail(listOrderDetail[0]!);
        setSearchParams({ id: listOrderDetail[0]?.id?.toString() ?? "" });
      }
    }
  }, [
    searchParams.get("id"),
    listOrderDetail?.length,
    createOrderDetail,
    editOrderDetail,
  ]);

  return (
    <div className="d-flex">
      {!open ? (
        <>
          <div className="list col-5 ">
            <div className=" px-3 my-4  d-flex align-items-center">
              <div className="title d-flex ">
                <h5>Retailer Order</h5>
              </div>

              <div className="filter ms-auto me-3 d-flex align-items-center">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100 me-2"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(listOrderDetailMasterStart(null));
                  }}
                  className="mx-2"
                  style={{
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  icon={["fas", "arrows-rotate"]}
                />
                <div
                  className="mx-2 add-branch"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <FontAwesomeIcon
                    title="add general setting"
                    style={{
                      fontWeight: "700",
                      fontSize: "21px",
                      color: "white",
                      padding: "0",
                      background: "#46a4e3",
                      marginBottom: "2px",
                    }}
                    icon={["fas", "plus"]}
                  />
                </div>
              </div>
            </div>
            <div
              className="list"
              style={{
                overflow: "scroll",
                height: "80vh",
                background: "#fafafa",
              }}
            >
              <>
                {listOrderDetail &&
                  listOrderDetail
                    .filter((item) => {
                      return Object.values(item)
                        .join("")
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    .map((val, key) => (
                      <>
                        <div
                          key={key + 1}
                          id={orderDetail.id === val.id ? "active-lists" : ""}
                          className={"d-flex lists flex-column mt-2  p-3"}
                          style={{
                            height: "100px",
                            background: "#FFFFFF",
                          }}
                          onClick={() => {
                            val.id &&
                              setSearchParams({ id: val.id?.toString() });
                          }}
                        >
                          <div
                            className="mx-2 partner-name"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {val.retail?.name}
                            <FontAwesomeIcon
                              className="mx-2"
                              style={{
                                fontSize: "22px",
                                cursor: "pointer",
                                display:
                                  orderDetail.id === val.id ? "flex" : "none",
                              }}
                              icon={["fas", "pen-to-square"]}
                              onClick={() => {
                                handleClickOpen();
                                setInitialValues(orderDetail ?? {});
                              }}
                            />
                          </div>
                          <div className="d-flex mt-auto partner-locations align-items-center">
                            <p className="ms-2 me-auto mb-0">{val.order_no}</p>
                            <p className={"ms-auto me-2 mb-0 text-yellow"}>
                              {val?.entrydate
                                ? new Date(val.entrydate).toLocaleDateString()
                                : ""}
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
              </>
            </div>
          </div>

          <OrderDetailMasterList orderDetail={orderDetail} />
        </>
      ) : (
        <>
          <OrderDetailMasterForm
            open={open}
            initialValues={initialValues}
            handleClose={handleClose}
            setInitialValues={setInitialValues}
          />
        </>
      )}
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!listOrderDetailLoading}
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
      </>
    </div>
  );
};

export default OrderDetailMaster;
